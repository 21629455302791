import SvgIcConvenios from 'atomic/atm.svg-icon/ic-convenios'
import SvgIcCorpoClinico from 'atomic/atm.svg-icon/ic-corpo-clinico'
import SvgIcEducacaoMedica from 'atomic/atm.svg-icon/ic-educacao-medica'
import SvgIcExames from 'atomic/atm.svg-icon/ic-exames'
import SvgIcInstrucoesResultados from 'atomic/atm.svg-icon/ic-instrucoes-resultados'
import SvgIcManualDeExames from 'atomic/atm.svg-icon/ic-manual-de-exames'
import SvgIcServicos from 'atomic/atm.svg-icon/ic-servicos'
import SvgIcUnidades from 'atomic/atm.svg-icon/ic-unidades'
import * as React from 'react'
import { appPaths, externalPaths } from 'utils/path'
import { QuickAccessItem } from 'utils/model/quick-access'

export const doctorQuickAccess: QuickAccessItem[] = [
  {
    path: externalPaths.fleuryExamResultsDoctor.path,
    icon: <SvgIcInstrucoesResultados height={24} />,
    title: 'Resultados de exames'
  },
  {
    path: appPaths.examManual.path.doctorUrl,
    icon: <SvgIcManualDeExames height={24} />,
    title: 'Manual de exames'
  },
  {
    path: appPaths.medicalEducation.path.doctorUrl,
    icon: <SvgIcEducacaoMedica height={24} />,
    title: 'Fleury Med'
  },

  {
    path: appPaths.ourSpecialists.path.doctorUrl,
    icon: <SvgIcCorpoClinico height={24} />,
    title: 'Corpo clínico'
  },
  {
    path: appPaths.otherServices.path.doctorUrl,
    icon: <SvgIcServicos height={24} />,
    title: appPaths.otherServices.name
  }
]

export const clientQuickAccess: QuickAccessItem[] = [
  {
    path: externalPaths.fleuryExamResultsClient.path,
    icon: <SvgIcInstrucoesResultados height={24} />,
    title: 'Resultados de exames'
  },
  {
    path: appPaths.exams.path.clientUrl,
    icon: <SvgIcExames height={24} />,
    title: 'Exames e vacinas'
  },
  {
    path: appPaths.convenio.path.clientUrl,
    icon: <SvgIcConvenios height={24} />,
    title: 'Convênios atendidos'
  },
  {
    path: appPaths.units.path.clientUrl,
    icon: <SvgIcUnidades height={24} />,
    title: 'Nossas unidades'
  },
  {
    path: appPaths.differentiatedServices.path.clientUrl,
    icon: <SvgIcServicos height={24} />,
    title: appPaths.otherServices.name
  }
]
