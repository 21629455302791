import React from 'react'

const SvgIcEducacaoMedica = props => (
  <svg viewBox="0 0 32 32" {...props}>
    <defs>
      <path
        d="M22 28h3v-6h-3v6zm-10 0h3v-6h-3v6zm-5 0h3V11H7v17zm20 0h4a1 1 0 010 2H1a1 1 0 010-2h4V3a1 1 0 011-1h5a1 1 0 011 1v10h3V8a1 1 0 011-1h5a1 1 0 011 1v5h4a1 1 0 011 1v14zm-7 0V9h-3v19h3zm5-8v-1h-3v1h3zm-10 0v-1h-3v1h3zm10-3v-2h-3v2h3zm-10 0v-2h-3v2h3zm-5-8V8H7v1h3zm0-3V4H7v2h3z"
        id="ic-educacao-medica_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-educacao-medica_svg__b" fill="#fff">
        <use xlinkHref="#ic-educacao-medica_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-educacao-medica_svg__a" />
    </g>
  </svg>
)

export default SvgIcEducacaoMedica
