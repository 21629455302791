import { CosmicjsHomeCliente } from "site/src/data/graphql/graphql-types";
import React from "react";
import { CardKind } from "site/src/components/mol.card/card.component";
import {
  CardData,
  CardsSection,
  CardsSectionMetadataProps
} from "site/src/components/org.cards-section/cards-section.component";
import { appPaths } from "utils/path";
import { getDetailPageUrl } from "utils/url";

interface HomeDifferentialsSectionProps {
  cosmicjsHomeCliente?: CosmicjsHomeCliente;
}

const ClientHomeDifferentialsSection: React.FunctionComponent<HomeDifferentialsSectionProps> = props => {
  const metadata = props.cosmicjsHomeCliente.metadata;
  const differentialsCard = metadata.diferenciaisCarrossel
    .slice(0, 3)
    .map(card => ({
      imgSrc: card.metadata.thumbnail.imgix_url,
      imgAlt: `Icone do diferencial ${card.title}`,
      title: card.title,
      body: card.metadata.descricaoCurta,
      linkText: "Leia mais",
      linkTo: getDetailPageUrl(
        appPaths.differentiatedServices.path.clientUrl,
        card.slug
      ),
      kind: CardKind.image
    })) as [CardData, CardData, CardData];

  return (
    <CardsSection
      metadata={
        {
          sectionTitle: metadata.diferenciaisAssunto,
          displayText: metadata.diferenciaisTitulo,
          sectionDescription: metadata.diferenciaisDescricao,
          buttonText: "Ver todos diferenciais",
          buttonLinkto: appPaths.differentiatedServices.path.clientUrl
        } as CardsSectionMetadataProps
      }
      cardsData={[
        differentialsCard[0],
        differentialsCard[1],
        differentialsCard[2]
      ]}
      id="client-differentials-cards"
    />
  );
};

export default ClientHomeDifferentialsSection;
