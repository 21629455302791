import React from 'react'

const SvgIcServicos = props => (
  <svg viewBox="0 0 32 32" width={32} height={32} {...props}>
    <defs>
      <path
        d="M23.008 25.876A4.002 4.002 0 0124 18a4 4 0 01.992 7.876c.005.04.008.082.008.124a5 5 0 01-5 5h-1a6 6 0 01-6-6v-5.05C7.947 19.45 4 15.186 4 10V3a2 2 0 012-2h2.9a1 1 0 110 2H6v7a8 8 0 1016 0V3h-2.9a1 1 0 010-2H22a2 2 0 012 2v7c0 5.185-3.947 9.449-9 9.95V25a4 4 0 004 4h1a3 3 0 003-3c0-.042.003-.083.008-.124zM24 24a2 2 0 100-4 2 2 0 000 4zm0-1a1 1 0 110-2 1 1 0 010 2z"
        id="ic-servicos_svg__a"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="ic-servicos_svg__b" fill="#fff">
        <use xlinkHref="#ic-servicos_svg__a" />
      </mask>
      <use fill={props.fill} fillRule="nonzero" xlinkHref="#ic-servicos_svg__a" />
    </g>
  </svg>
)

export default SvgIcServicos
